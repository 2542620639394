<template>

    <div class="mb-3" v-if="data.type == 'string'">
        <label class="form-label text-capitalize">{{ fieldName }}</label>
        <input name="text" autocomplete="off"  :value="modelValue"  @input="$emit('update:modelValue', $event.target.value)" class="form-control">
    </div>
    <div class="mb-3" v-if="data.type == 'text'">
        <label class="form-label text-capitalize">{{ fieldName }}</label>
        <ckeditor :editor="editor" v-model="editorData" @input="$emit('update:modelValue', editorData)" :config="editorConfig"></ckeditor>
    </div>
    <div class="mb-3" v-if="data.type == 'image'">
        <label class="form-label text-capitalize mb-0">{{ fieldName }}</label>
        <small class="form-text text-muted mb-2">
            Min size: {{ data.width }}px x {{ data.height }}px
        </small>


        <button @click="pickFile()" class="btn btn-outline-dark mr-2" :disabled="uploading == 1">
            <span v-if="uploading"><i class="sap sap-action-settings sap-spin"></i></span>
            <span v-else>UPLOAD</span>
        </button>
        <input type="file" :ref="'fileInput'+inputKey" @change="upload" style="display:none">

<!--        <button data-toggle="modal" :data-target="'#library'+inputKey" class="btn btn-outline-dark mr-2">LIBRARY</button>-->
        <button v-if="modelValue" data-toggle="modal" :data-target="'#image'+inputKey" class="btn btn-outline-dark">EDIT</button>

        <!-- Modal -->
        <div class="modal fade" :id="'library'+inputKey" tabindex="-1" role="dialog" >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Image Library</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row px-2">
                            <div class="col-4 px-2 mb-3" v-for="(image) in library">
                                <div  @click="selectImage('/images/library/'+image)" :style="'height: 120px; background-image:url(/images/library/'+image+'); background-position:center; background-size:cover;'" class="w-100" :class="{activeImage : modelValue == '/images/library/'+image}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" :id="'image'+inputKey" tabindex="-1" role="dialog" >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Image Editor</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <small class="form-text text-muted mb-3">
                            Drag image around to position. Zoom by scrolling over the image
                        </small>

                        <vue-cropper
                            :ref="'cropper'+inputKey"
                            :src="modelValue"
                            :viewMode="0"
                            dragMode="move"
                            :autoCropArea="1"
                            :cropBoxMovable=false
                            :cropBoxResizable=false
                            :guides=false
                            :aspectRatio="aspectRatio"
                            :minContainerWidth="cropperWidth"
                            :minContainerHeight="cropperHeight"
                            :minCropBoxWidth="cropperWidth"
                            :minCropBoxHeight="cropperHeight"
                            class="m-auto bg-image"
                            :style="'width:250px; height:'+cropperHeight+'px;'"
                            :container-style="{ 'width': '250', 'height': cropperHeight }"

                        >
                        </vue-cropper>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCEL</button>
                        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="updateCrop" :disabled="saving == 1">
                            <span v-if="saving"><i class="sap sap-action-settings sap-spin"></i></span>
                            <span v-else>SAVE</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>

import VueCropper from 'vue-cropperjs';

export default {
    components: {
        VueCropper
    },
    props: ['inputKey','name','data','modelValue','library'],
    computed:{
        fieldName: function(){
            return this.name.replace(/([a-z])([A-Z])/g, '$1 $2');
        }
    },
    data(){

        let aspectRatio = this.data.width/this.data.height;

        return{
            cropperWidth: 250,
            cropperHeight: 250/aspectRatio,
            aspectRatio: aspectRatio,
            saving: false,
            uploading: false,
            editor: ClassicEditor,
            editorData: this.modelValue,
            editorConfig: {
                fontColor: {
                    colors: [
                        '#000000', '#F0AB00'
                    ],
                },
                toolbar: [ 'bold', 'italic', 'fontColor', '|', 'link' ,'|','numberedList', 'bulletedList']
            },
        }
    },
    methods:{
        selectImage:function(path){
            //choose image from library
            this.$emit('update:modelValue', path);
            this.$refs["cropper"+this.inputKey].replace(path);
        },
        pickFile: function(){
            //choose image from computer
            this.$refs["fileInput"+this.inputKey].click();
        },
        updateCrop: function(){
            var croppedCanvas = this.$refs["cropper"+this.inputKey].getCroppedCanvas({
                maxWidth:this.data.width * 1.5
            });

            if(typeof this.$refs['cropper'+this.inputKey] !== 'undefined' && croppedCanvas !== null) {
                //let dataURL = croppedCanvas.toDataURL('image/jpeg');
                let dataURL = croppedCanvas.toDataURL();
                let self = this;
                self.saving = false;

                axios.post('/save-crop',{data:dataURL}).then(function (response){
                    self.saving = false;
                    self.$emit('update:modelValue', response.data);
                });

            }else{
                return false
            }
        },
        upload: function(){
            var self = this;
            self.uploading = true;
            let formData = new FormData();
            formData.append('file', this.$refs['fileInput'+this.inputKey].files[0]);

            axios.post('/upload',formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(function (response){
                self.uploading = false;
                self.$emit('update:modelValue', response.data);
                self.$refs["cropper"+self.inputKey].replace(response.data);
            });
        }
    },
}

</script>
