require('./bootstrap');
require('jquery');

require('bootstrap-datepicker')

import CKEditor from '@ckeditor/ckeditor5-vue';

import { createApp } from 'vue';
import Editor from './components/editor.vue'
import Ebook from './components/ebook.vue'



const app = createApp({
    components:{
        Editor,
        Ebook,
    },
}).use( CKEditor ).mount("#app");

$(document).ready(function (){
    $('.datepicker').on('change',function (){
        $.post($('#filters').attr('action'),$('#filters').serialize(),function (data){
            $('.list').html(data);
        });
    })

    $('.datepicker').datepicker({
        format: 'yyyy-mm-dd'

    });

})
