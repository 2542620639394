<template>
    <div class="image-wrap w-100 h-100 position-absolute right">
        <div class="background background-cover background-center w-100 h-100" :style="'background-image: url('+ebook.frontcover_image+')'"></div>
    </div>

    <section class="home h-100">

        <button @click="start()" class="next-chapter btn btn-primary">
            <div class="row align-items-center">
                <div class="col-auto text-left">
                    Start Here
                </div>
                <div class="col">
                    <i class="sap sap-navigation-right-arrow "></i>
                </div>
            </div>
        </button>


        <div class="row align-items-center h-100 w-100">
            <div class="col col-md-6 pr-md-0">
                <div class="p-5 panel">
                    <div class="h1 mb-1 bold" v-html="ebook.frontcover_heading"></div>
                    <h2 class="mb-1">
                        {{ebook.frontcover_subheading}}
                    </h2>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
    export default {
        name: 'cover',

        props:{
            ebook:Object
        },
        methods: {
            start() {

                var hash = $('.swiper-slide-active').nextAll('.chapter:first').attr('data-hash');
                if (typeof hash != 'undefined') {
                    window.location.hash = hash;
                }

            },


        },
    };
</script>
