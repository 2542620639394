<template>
    <div class="image-wrap w-100 h-100 position-absolute right">
        <div class="background w-100 h-100 background-cover background-center" :class="{hasMobile:slide.content.mobile_image}" :style="'background-image: url('+slide.content.image+')'"></div>
        <div v-if="slide.content.mobile_image" class="d-md-none background w-100 h-100 background-contain background-center" :style="'background-image: url('+slide.content.mobile_image+')'">
        </div>
    </div>

    <section class="chapter h-100">

        <button v-if="!last" @click="nextChapter()" class="next-chapter btn btn-primary">
            <div class="row align-items-center">
                <div class="col-auto text-left">
                    Next <br> Chapter
                </div>
                <div class="col">
                    <i class="sap sap-navigation-right-arrow "></i>
                </div>
            </div>
        </button>

        <div class="row align-items-lg-center  h-100 w-100">
            <div class="col-12 col-lg-6">
                <div class="pl-5 p-3">
                    <h1 class="mb-1 mt-5 pt-5 pt-lg-0 mt-lg-0 text-uppercase">
                        {{ slide.content.chapter }}:<br>
                        <i class="font-italic" v-html="slide.content.heading"></i>
                    </h1>
                    <h2 class="mb-3">
                        {{ slide.content.subheading }}
                    </h2>
                    <button @click="nextPage()" class="mt-2 mt-lg-5 btn btn-primary">
                        Read on <i class="ml-2 sap sap-navigation-down-arrow align-middle"></i>
                    </button>
                </div>
            </div>

        </div>

    </section>
</template>
<script>
    export default {
        name: 'chapter',

        props: {
            slide: Object,
            last: Boolean
        },
        methods:{
            nextChapter() {
                var hash = $('.swiper-slide-active').nextAll('.chapter:first').attr('data-hash');
                if (typeof hash != 'undefined') {
                    window.location.hash = hash;
                }
            },
            nextPage() {
                this.$emit('changeDirection','vertical')

                var hash = $('.swiper-slide-active').next().attr('data-hash');
                if (typeof hash != 'undefined') {
                    window.location.hash = hash;
                }

                var self = this;
                setTimeout(function (){
                    self.$emit('changeDirection','horizontal')
                },1000);


            },

        }
    };
</script>
