<template>

    <section class="inner h-100 ">

        <div class="row h-100  ">

            <div class="col-lg-7 ">
                    <h2 class="mb-2">
                        {{ chapter.content.chapter }}:
                        <i class="font-italic" v-html="chapter.content.heading"></i>
                    </h2>

                    <h1>
                        {{slide.content.heading}}
                    </h1>

                    <div class="intro pb-4" v-html="slide.content.text"></div>

                    <!-- mobile image -->
                    <div class=" mb-4 d-lg-none">
                        <img :src="slide.content.image" />
                    </div>


            </div>
            <div class="d-none d-lg-block col-5 h-100 pr-0">
                <div class="background w-100 h-100 background-center background-cover" :style="'background-image: url('+slide.content.image+')'">
                </div>
            </div>


        </div>

    </section>
</template>
<script>
    export default {

        data(){
            return {
            }
        },
        methods: {

        },
        props: {
            slide: Object,
            chapter: Object,
        },


    };
</script>
