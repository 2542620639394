<template>
    <div class="container-fluid">
        <div class="row ">
            <div class="col py-3 bg-grey">
<!--                REMOVE as they don't work any more-->
<!--                <div class="float-right">-->
<!--                    <button class="btn btn-primary prev" >PREVIOUS</button>-->
<!--                    &nbsp;-->
<!--                    <button class="btn btn-primary next">NEXT</button>-->
<!--                </div>-->
                <h3 class="medium mb-0 mt-1 text-uppercase ">
                    <template v-if="currentSlide != 0">
                        {{ slides[chapter].content.chapter }}:
                        <i class="font-italic">{{ slides[chapter].content.heading }}</i>
                    </template>
                    <template v-else>
                        Front cover
                    </template>
                </h3>

            </div>
            <div class="col-4 align-self-center">
               <button class="btn btn-primary float-right" @click="save()" :disabled="saving == 1">
                   <span v-if="saving == 'saved'" class="mx-3"><i class="sap sap-sys-enter"></i></span>
                   <span v-else-if="saving" class="mx-3"><i class="sap sap-action-settings sap-spin"></i></span>
                   <span v-else>SAVE</span>
               </button>

                <button type="button" class=" mr-2 btn btn-primary float-right" data-toggle="modal" data-target="#pages">PAGES
                </button>
                <button type="button" class=" mr-2 btn btn-primary float-right" data-toggle="modal" data-target="#settings">SETTINGS
                </button>

                <h4 class="medium mb-0 mt-1">Editor</h4>
            </div>
        </div>
        <div class="row bg-light-grey" style="min-height: 100%">
            <div class="col-8 pt-4 pb-4 " id="ebook">
                <div class="fixed-container">
                    <div class="inner-container">
                        <Ebook :slides="slides" :ebook="ebook" :index="currentSlide" @changeSlide="changeSlide"></Ebook>
                    </div>
                </div>
            </div>
            <div class="col-4 p-4 bg-white shadow overflow-hidden">
                <div class="editor-box">

                    <p v-if="error" class="text-danger">{{error}}</p>

                    <template v-if="currentSlide == 0 ">
                        <!-- COVER PAGE -->

                        <Field :data="{type:'text'}" :name="'Heading'" v-model="ebook.frontcover_heading"></Field>
                        <Field :data="{type:'string'}" :name="'Subheading'" v-model="ebook.frontcover_subheading"></Field>
                        <Field :inputKey="cover" :library="libraryProp" :data="{type:'image', height:810, width:1920}" :name="'Image'" v-model="ebook.frontcover_image" ></Field>

                    </template>
                    <template v-else-if="chapterSlide == -1">

                        <Field :data="{type:'string'}" :name="'Chapter'" v-model="slides[chapter].content['chapter']"></Field>
                        <Field :data="{type:'string'}" :name="'Heading'" v-model="slides[chapter].content['heading']"></Field>
                        <Field :data="{type:'string'}" :name="'Subheading'" v-model="slides[chapter].content['subheading']"></Field>
                        <Field :inputKey="chapter" :library="libraryProp" :data="{type:'image', height:900, width:1920}" :name="'Image'" v-model="slides[chapter].content['image']"></Field>
                        <Field :inputKey="chapterMobile" :library="libraryProp" :data="{type:'image', height:400, width:600}" :name="'Mobile image'" v-model="slides[chapter].content['mobile_image']"></Field>


                    </template>
                    <template v-else>
                        <div class="mb-3" >
                            <label class="form-label">Template</label>
                            <select v-model="slides[chapter].slides[chapterSlide].template" @change="templateChange(slides[chapter].slides[chapterSlide])" class="form-control">
                                <option value="">Please select one</option>
                                <template v-for="(template) in templatesProp">
                                    <option :value="template.file">
                                        {{template.name}}
                                    </option>
                                </template>
                            </select>
                        </div>

                        <template v-for="(input,key) in fieldTypes[slides[chapter].slides[chapterSlide].template]">

                            <Field v-if="input.type!='option'" :library="libraryProp" :inputKey="key" :data="input" :name="key" v-model="slides[chapter].slides[chapterSlide].content[key]"></Field>
                            <template v-else>
                                <div class="mb-3" >
                                    <label class="form-label text-capitalize">{{deCamelCase(key)}}</label>
                                    <select v-model="slides[chapter].slides[chapterSlide].content[key]" class="form-control">
                                        <option v-for="(option,optionName) in input.options">{{optionName}}</option>
                                    </select>
                                </div>

                                <Field v-for="(option,name) in input.options[slides[chapter].slides[chapterSlide].content[key]]" :inputKey="key+'-'+name" :data="option" :name="name" :library="libraryProp" v-model="slides[chapter].slides[chapterSlide].content[name]" ></Field>

                            </template>



                        </template>
                    </template>


                </div>
            </div>
        </div>

    </div>


    <!-- Modals -->
    <div class="modal fade" id="pages" tabindex="-1" role="dialog" >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Pages</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">


                        <draggable
                            :list="slides"
                            handle=".handle"
                            @start="drag=true"
                            @end="drag=false"
                            item-key="id"
                            tag="ul"
                            class="list-unstyled mb-0"
                            >

                            <template  #item="{ element, index: chapter }">
                                <li class="keyline">
                                    <a href="#" @click="deleteChapter(chapter)" class="float-right"><i class="sap sap-delete"></i></a>
                                    <i class="sap sap-menu2 handle pr-2"></i>
                                    <a href="#" @click="goto(chapter)" >{{element.content.heading}}</a>


                                    <draggable
                                        :list="element.slides"
                                        handle=".handle"
                                        @start="drag=true"
                                        @end="drag=false"
                                        item-key="id"
                                        dragoverBubble="true"
                                        tag="ul"
                                        class="list-unstyled mb-0"
                                    >
                                        <template  #item="{ element, index: slide }">
                                            <li class="pl-3">
                                                <a href="#" @click="deletePage(chapter,slide)" class="float-right"><i class="sap sap-delete "></i></a>
                                            <i class="handle sap sap-menu2  pr-2"></i>

                                            <a href="#" @click="goto(chapter+'slide'+slide)" >{{element.content.heading}} </a>
                                            </li>
                                        </template>
                                    </draggable>
                                    <a href="#" @click="addPage(element.slides)" class="pl-3"><i class="sap sap-add"></i></a>
                                </li>
                            </template>
                        </draggable>
                        <a href="#" @click="addChapter()"><i class="sap sap-add"></i></a>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="settings" tabindex="-1" role="dialog" >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12 mb-3">
                        <label class="form-label text-capitalize">Internal name</label>
                        <input name="text" v-model="ebook.name" class="form-control">
                        <small class="form-text text-muted ">
                            Only used inside the CMS
                        </small>
                    </div>
                    <div class="col-12  mb-3">
                        <label class="form-label text-capitalize">Full name</label>
                        <input name="text" v-model="ebook.title" class="form-control">
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-label text-capitalize">eBook URL</label>
                        <input name="text" v-model="ebook.url" class="form-control">
                        <small class="form-text text-muted ">
                            Changing this will mean that the old link will no longer work
                        </small>
                    </div>



                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">SAVE</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Ebook from "./ebook";
    import Field from "./partials/fields";
    import draggable from 'vuedraggable'
    const FieldTypes = require('../field-types')
    import {lorem} from "../lorem.js"

    export default {
        name: 'editor',

        props:{
            slidesProp:Object,
            ebookProp:Object,
            libraryProp:Object,
            templatesProp:Object,
        },
        components: {
            Ebook,
            Field,
            draggable
        },
        data() {

            return {
                error:'',
                saving:false,
                chapter:0,
                chapterSlide:0,
                currentSlide:0,
                ebook:this.ebookProp,
                slides:this.slidesProp,
                fieldTypes:FieldTypes,  //ENSURE TO DEFINE FIELDS IN /js/field-types.js

            }
        },
        created(){
          if(this.ebook.id==0){
              $('#settings').modal('show');
              this.addChapter();
              this.ebook.frontcover_heading = '<p>Heading</p>';
              this.ebook.frontcover_subheading = 'Subheading';
              this.ebook.frontcover_image = '/images/sample.png';
          }
        },
        methods: {
            templateChange(element){

                let template = element.template
                for(const field in this.fieldTypes[element.template]){
                    let type = this.fieldTypes[template][field].type;
                    if(typeof element.content[field] == 'undefined'){
                        if(type == 'image'){
                            element.content[field] = "/images/sample.png";
                        }
                        if( type == 'string'){
                            element.content[field] = lorem(20);
                        }
                        if(type == 'text' ){
                            element.content[field] = '<p>'+lorem(200)+'</p>';
                        }

                        if( type == 'option'){
                            element.content[field] = Object.keys(this.fieldTypes[template][field]['options'])[0];
                            for(const optionField in this.fieldTypes[template][field]['options'][element.content[field]]){
                                let type = this.fieldTypes[template][field]['options'][element.content[field]][optionField].type;
                                if(type == 'image'){
                                    element.content[optionField] = "/images/sample.png";
                                }
                                if( type == 'string'){
                                    element.content[optionField] = lorem(20);
                                }
                                if(type == 'text' ){
                                    element.content[optionField] = '<p>'+lorem(200)+'</p>';
                                }
                            }
                        }
                    }
                }
                this.$forceUpdate();
            },
            addPage(element){
                let slide = {
                    content: { heading : 'New Page'},
                    id:0,
                    template:this.templatesProp[0].file
                }
                this.templateChange(slide);
                element.push(slide);

            },
            addChapter(){
                let template = this.templatesProp[0].file;
                this.slides.push({
                    content:{
                        chapter:"New Chapter",
                        heading:"Heading",
                        subheading:"Subheading",
                        image:"/images/sample.png",
                    },
                    slides:[],
                    id:0,
                });
                this.addPage(this.slides[this.slides.length - 1].slides);
            },
            deletePage(chapter,slide){
                console.log(this.chapterSlide);
                console.log(slide);
                if(this.chapter == chapter && this.chapterSlide == slide){
                    this.goto('#cover');
                }

                var self = this;
                setTimeout(function (){
                    self.slides[chapter].slides.splice(slide,1);

                },500)
            },
            deleteChapter(index){

                if(this.chapter == index){
                    this.goto('#cover');
                }

                var self = this;
                setTimeout(function (){
                    self.slides.splice(index,1);
                },500)

            },
            deCamelCase(s){
                return s.replace(/([a-z])([A-Z])/g, '$1 $2');
            },
            goto(hash){
                window.location.hash = hash;
            },
            changeSlide(index){
                let hash = window.location.hash;
                let numbers = hash.replace('#','').split('slide');

                this.chapter = parseInt(numbers[0]);
                this.chapterSlide = typeof numbers[1] !== 'undefined' ? parseInt(numbers[1]) : -1;
                this.currentSlide = index;
            },
            save(){
                let self = this;
                self.saving = true;
                axios.post('/save', {
                    slides: self.slides,
                    ebook: self.ebook,
                }).then(function (response){
                    self.ebook.id = response.data
                    self.saving = 'saved';
                    self.error = '';
                    setTimeout(function (){
                        self.saving = false;
                    },3000);
                }).catch(function (response){
                    self.error = 'There has been an error saving, please try again.';
                    self.saving = false;
                });
            },
        },
    };

</script>
