<template>
    <header class="nav-bar-left shadow">
            <div class="container-fluid">
                <div class="row align-content-center">
                    <div class="col-4 col-md-5 pr-0">
                        <div class="mobile">
                            <div class="hamburger-menu" @click="hamburgerMenu()">
                                <div class="bar"></div>
                            </div>
                        </div>
                        <div class="mobile-nav hide">
                            <div class="menu-list">
                                <ul class="list-unstyled" v-for="(chapterSlide,chapterIndex) in slides">
                                    <li><a class="text-uppercase" :href="'#'+chapterIndex">{{chapterSlide.content.chapter}}: <i class="font-italic">{{chapterSlide.content.heading}}</i></a></li>
                                    <template v-for="(slide,slideIndex) in chapterSlide.slides">
                                        <li><a :href="'#'+chapterIndex+'slide'+slideIndex">{{slide.content.heading}}</a></li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div class="main-logo pl-3 d-none d-md-flex">
                            <a class="slide-to text-black" href="#cover">
                                {{ ebook.title }}
                            </a>
                        </div>
                    </div>
                    <div class="col-4 col-md-2  text-center additional-nav align-self-center">
                        <span class="prev" @click="prevChapter()">
                            <i class="sap sap-navigation-left-arrow"></i>
                        </span>
                        <span class="seperator">|</span>
                        <span class="next" @click="nextChapter()">
                            <i class="sap sap-navigation-right-arrow"></i>
                        </span>
                    </div>
                    <div class="col-5 right-logo d-none d-md-flex  justify-content-end">
                        <img class="align-self-center" src="/images/logos/best-run-sap-black.png" />
                    </div>
                </div>
            </div>
        </header>

        <swiper
            :hashNavigation="{ watchState:true }"
            :threshold="100"
            :slides-per-view="1"
            @swiper="onSwiper"
            :navigation="{  nextEl: '.next-page', prevEl: '.prev-page', }"
            @slideChangeTransitionEnd="onSlideChange"
            @slideChange="onBeforeSlideChange"
        >
<!--
-->
            <swiper-slide data-hash="cover"><Cover :ebook="ebook"></Cover></swiper-slide>
            <template v-for="(chapterSlide,chapterIndex) in slides">
                <swiper-slide  v-bind:data-hash="chapterIndex" class="chapter" v-bind:data-chapter-id="chapterSlide.id" >
                    <Chapter :slide="chapterSlide" :last="chapterIndex == slides.length - 1 ? true : false" @changeDirection="changeDirection" ></Chapter>
                </swiper-slide>
                <template v-for="(slide,index) in chapterSlide.slides">
                    <swiper-slide v-bind:data-hash="chapterIndex+'slide'+index" v-bind:data-page-id="slide.id">
                        <span class="prev-page">
                            <i class="sap sap-navigation-left-arrow"></i>
                        </span>
                        <span class="next-page">
                            <i class="sap sap-navigation-right-arrow"></i>
                        </span>
                        <component v-if="slide.template" :is="slide.template" :slide="slide" :chapter="chapterSlide"></component>
                    </swiper-slide>
                </template>
            </template>
        </swiper>

        <div class="modal video-modal fade" id="videoModal" tabindex="-1" role="dialog" aria-modal="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <i class="sap sap-decline"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <iframe class="video" src="" data-ready="true" width="640" height="360" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </div>

</template>


<script>

    const TimeMe = require('timeme.js');

    import SwiperCore, { Navigation, HashNavigation } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    SwiperCore.use([Navigation,HashNavigation]);

    import Cover from './templates/cover.vue'
    import Chapter from './templates/chapter.vue'
    import ImageCenter from './templates/image-center.vue'
    import ImageLeftOffer from './templates/image-left-offer.vue'
    import ImageLeftTwoOffers from './templates/image-left-two-offers.vue'
    import ImageRightOffer from './templates/image-right-offer.vue'
    import ImageRight from './templates/image-right.vue'
    import ImageBottom from './templates/image-bottom.vue'
    import ImageQuote from './templates/image-quote.vue'

    export default {
        props:{
            slides:Object,
            ebook:Object,
            pageTitle:String,
            index:Number,
        },
        components: {
            Cover,
            Chapter,
            ImageCenter,
            ImageLeftTwoOffers,
            ImageLeftOffer,
            ImageRightOffer,
            ImageRight,
            ImageBottom,
            ImageQuote,
            Swiper,
            SwiperSlide,
        },
        data(){
            return{
                swiperObj: {},
            }
        },
        created() {
            if($(window).width() > 768){
                $(window).on('mousemove',function (event){
                    if(event.pageX < 300){
                        $('.prev-page').fadeIn(100);
                    }else {
                        $('.prev-page').fadeOut(100);
                    }
                    if(($(window).width() - event.pageX) < 300){
                        $('.next-page').fadeIn(100);
                    }else{
                        $('.next-page').fadeOut(100);
                    }
                })

            }
        },
        methods: {
            hamburgerMenu(){
                $('.bar').toggleClass('animate');
                var mobileNav = $('.mobile-nav');
                mobileNav.toggleClass('hide show');
                $('.hamburger-menu').toggleClass('open-hamburger');

                $(".mobile-nav ul li").show();
                $(".mobile-nav h3").show();
            },
            onSwiper(swiper) {

                this.swiperObj = swiper;
                this.$emit('changeSlide',this.swiperObj.activeIndex)

                this.checkNav();

            },
            onBeforeSlideChange() {

                this.handleUpdateReportingViewTime()

            },
            onSlideChange() {
                this.$emit('changeSlide',this.swiperObj.activeIndex)

                var current_page = $('.swiper-slide-active').attr('data-hash');
                console.log(current_page);
                $('.menu-list li').removeClass('active');
                $('.menu-list a[href="#'+current_page+'"]').parent().addClass('active');
                this.checkNav();

                TimeMe.setCurrentPageName(current_page);


            },
            changeDirection(direction){
                this.swiperObj.changeDirection(direction);
            },
            checkNav(){
                if(window.location.hash == '#0' ||  window.location.hash == '#cover' || window.location.hash == ''){
                    $('.prev').css('opacity',0);
                }else{
                    $('.prev').css('opacity',1);
                }

                var last = $('.swiper-slide.chapter:last').attr('data-hash');
                if(window.location.hash.startsWith('#'+last)){
                    $('.next').css('opacity',0);
                }else{
                    $('.next').css('opacity',1);
                }

                if($('.swiper-slide-active').hasClass('chapter') || window.location.hash == '#cover' || window.location.hash == ''){
                    $('.additional-nav').css('opacity',1);
                }else{
                    $('.additional-nav').css('opacity',0);
                }



            },
            nextChapter() {
                var hash = $('.swiper-slide-active').nextAll('.chapter:first').attr('data-hash');
                if (typeof hash != 'undefined') {
                    window.location.hash = hash;
                }
            },
            prevChapter() {
                var hash = $('.swiper-slide-active').prevAll('.chapter:first').attr('data-hash');
                if (typeof hash != 'undefined') {
                    window.location.hash = hash;
                }
            },
            handleUpdateReportingViewTime () {
                const url = '/log-view';
                var current_page = $('.swiper-slide-active').attr('data-page-id');
                var current_chapter = $('.swiper-slide-active').attr('data-chapter-id');

                const data = {
                    pageId: current_page,
                    chapterId: current_chapter,
                    ebookId: this.ebook.id,
                    time: TimeMe.getTimeOnCurrentPageInSeconds(),
                };

                axios.post(url,data)
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            },

            handleClickReporting (href,text) {
                const url = '/log-click';
                var current_page = $('.swiper-slide-active').attr('data-page-id');

                const data = {
                    pageId: current_page,
                    ebookId: this.ebook.id,
                    ctaText: text,
                    url: href,
                };

                axios.post(url,data)
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            },


        },
        watch:{
            index: function (val) {
                this.swiperObj.slideTo(val);
            },
        },
        mounted() {
            var self = this;
            // Start Time Me Timer
            TimeMe.initialize({
                idleTimeoutInSeconds: 30 // seconds
            });

            window.onbeforeunload = function(event) {
                self.handleUpdateReportingViewTime();
            };

            $(document).on('click','a',function (){
                var href = $(this).attr('href')
                var text = $(this).text()
                self.handleClickReporting(href, text);
            })

        }


    };

</script>
