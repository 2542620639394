<template>

    <section class="inner h-100 pr-0">

        <div class="row mr-0">
            <div class="col-12 col-lg-6">
                <h2 class="mb-2">
                    {{ chapter.content.chapter }}:
                    <i class="font-italic" v-html="chapter.content.heading"></i>
                </h2>

                <h1>
                    {{slide.content.heading}}
                </h1>
            </div>
        </div>
        <div class="row mr-0">
            <div class="col-12 col-lg-6">
                <div class="intro" v-html="slide.content.intro"></div>
                <div class="row">
                    <div class="col-12 col-md-6" v-html="slide.content.text"></div>
                    <div class="col-12 col-md-6 pizza quote" v-html="slide.content.quote"></div>
                </div>
            </div>

            <div class="col-6 offset-3 offset-md-0 col-lg-3 d-flex pr-0">
                <img class="align-self-center" :src="slide.content.image">
            </div>
            <div class="col-12 col-md-6 col-lg-3 pr-0 d-flex">
                <div class="align-self-center">
                    <template v-if="slide.content.howManyOffers == 'one' || slide.content.howManyOffers == 'two'">
                        <div class="offer-box d-flex pr-5">
                            <div class=" align-self-center">
                                <h3>{{ slide.content.offerOneTitle }}</h3>
                                <div v-html="slide.content.offerOneDescription"></div>
                                <p v-if="slide.content.offerOneLinkUrl">
                                    <a :href="slide.content.offerOneLinkUrl" target="_blank">
                                        {{slide.content.offerOneLinkText}}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </template>
                    <template v-if="slide.content.howManyOffers == 'two'">
                        <div class="offer-box d-flex mt-4 pr-5">
                            <div class=" align-self-center">
                                <h3>{{ slide.content.offerTwoTitle }}</h3>
                                <div v-html="slide.content.offerTwoDescription"></div>
                                <p v-if="slide.content.offerTwoLinkUrl">
                                    <a :href="slide.content.offerTwoLinkUrl" target="_blank">
                                        {{slide.content.offerTwoLinkText}}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
    export default {

        data(){
            return {
            }
        },
        methods: {

        },
        props: {
            slide: Object,
            chapter: Object,
        },


    };
</script>
