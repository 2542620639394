<template>

    <section class="inner h-100 p-lg-0">

        <div class="row h-100 m-lg-0 ">
            <div class="col-4 d-none d-md-block h-100 pl-0">
                <div class="background w-100 h-100 background-center background-cover" :style="'background-image: url('+slide.content.leftImage+')'">
                </div>
            </div>

            <div class="col col-md-8 pl-lg-5 pt-lg-5 flex-column d-flex">
                    <h2 class="mb-2">
                        {{ chapter.content.chapter }}:
                        <i class="font-italic" v-html="chapter.content.heading"></i>
                    </h2>

                    <h1>
                        {{slide.content.heading}}
                    </h1>

                    <img class="d-md-none mb-3" :src="slide.content.leftImage" alt="" />

                <div class="intro" v-html="slide.content.intro"></div>

                    <div v-if="slide.content.howManyOffers == 'one'" class="row">
                        <div class="col-12 col-lg-4" v-html="slide.content.text"></div>
                        <div class="col-12 col-lg-8">
                            <div class="row">
                                <div class="col-8 d-none d-md-block pr-0 min-h-offer">
                                    <div v-if="slide.content.offerOneImage" :style="'background-size:cover; background-image:url('+slide.content.offerOneImage+')'" class="h-100" />
                                </div>
                                <div class="col-12 col-md-4 offer-box d-flex">
                                    <div class=" align-self-center">
                                        <h3>{{ slide.content.offerOneTitle }}</h3>
                                        <div v-html="slide.content.offerOneDescription"></div>
                                        <p v-if="slide.content.offerOneLinkUrl">
                                            <a :href="slide.content.offerOneLinkUrl" target="_blank">
                                                {{slide.content.offerOneLinkText}}
                                            </a>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else  class="row flex-grow-1 d-flex" >
                        <div class="col-12 col-lg-5" v-html="slide.content.text"></div>
                        <div class="col-12 col-lg-7 pr-lg-4">
                            <div class="row h-100">
                                <div class="col-6 ">
                                    <div class="offer-box h-100">
                                        <div class="">
                                            <h3>{{ slide.content.offerOneTitle }}</h3>
                                            <div v-html="slide.content.offerOneDescription"></div>
                                            <p v-if="slide.content.offerOneLinkUrl">
                                                <a :href="slide.content.offerOneLinkUrl" target="_blank">
                                                    {{slide.content.offerOneLinkText}}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" v-if="slide.content.offerTwoTitle">
                                    <div class="offer-box h-100">
                                        <div class="">
                                            <h3>{{ slide.content.offerTwoTitle }}</h3>
                                            <div v-html="slide.content.offerTwoDescription"></div>
                                            <p v-if="slide.content.offerTwoLinkUrl">
                                                <a :href="slide.content.offerTwoLinkUrl" target="_blank">
                                                    {{slide.content.offerTwoLinkText}}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


            </div>
        </div>

    </section>
</template>
<script>
    export default {

        data(){
            return {
            }
        },
        methods: {

        },
        props: {
            slide: Object,
            chapter: Object,
        },


    };
</script>
