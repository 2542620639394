var string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean augue nibh, rutrum nec rutrum in, laoreet eu purus. Quisque fringilla, ipsum varius pellentesque finibus, mi diam ultricies nulla, ac facilisis arcu diam non orci. Nulla lacinia convallis massa, id tincidunt massa maximus in. Praesent imperdiet lacus at purus posuere tempor. Pellentesque volutpat malesuada mollis. Sed sit amet porta tellus, in ullamcorper nisi. Suspendisse imperdiet est a nulla posuere luctus. Pellentesque est orci, vestibulum ut dolor commodo, tempor tristique metus. Nulla at blandit enim, dignissim condimentum diam. Maecenas in cursus massa, et tincidunt eros. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam vulputate neque id massa tristique, vel tempus justo varius. Aenean sem tellus, condimentum at egestas non, ornare eget metus. Mauris euismod scelerisque sem eu finibus. Pellentesque eget ultrices elit, vel tincidunt ligula. Pellentesque at vestibulum risus, a efficitur sem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla eleifend nisl mattis pharetra tristique. Quisque tincidunt arcu ut commodo porttitor. Etiam pretium dui non pretium ultricies. In hac habitasse platea dictumst. Donec id varius eros. Maecenas euismod tempus lacus eu consequat. Donec at quam sit amet ex finibus venenatis. Suspendisse nunc augue, tincidunt ut erat eget, suscipit auctor metus.";


function lorem(length) {
    for(let i=length; i>=0; i--) {
        if(string.charAt(i) == " ") {
            return string.slice(0, i)+'';
        }
    }
}

export {lorem};
