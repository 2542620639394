let fieldTypes = {

    'image-left-offer':{
        heading: { type: 'string' },
        intro: { type: 'text' },
        text: { type: 'text' },
        leftImage: { type: 'image', height:810, width:640 },
        howManyOffers: { type: 'option', options: {
            one: {
                offerOneImage: { type: 'image', height:300, width:532 },
                offerOneTitle: { type: 'string' },
                offerOneDescription: { type: 'text' },
                offerOneLinkText: { type: 'string' },
                offerOneLinkUrl: { type: 'string' },
            },
            two: {
                offerOneTitle: { type: 'string' },
                offerOneDescription: { type: 'text' },
                offerOneLinkText: { type: 'string' },
                offerOneLinkUrl: { type: 'string' },
                offerTwoTitle: { type: 'string' },
                offerTwoDescription: { type: 'text' },
                offerTwoLinkText: { type: 'string' },
                offerTwoLinkUrl: { type: 'string' },
            }
        }},
    },
    'image-right-offer':{
        heading: { type: 'string' },
        intro: { type: 'text' },
        textOne: { type: 'text' },
        image: { type: 'image', height:400, width:400 },
        textTwo: { type: 'text' },
        offerTitle: { type: 'string' },
        offerDescription: { type: 'text' },
        offerLinkText: { type: 'string' },
        offerLinkUrl: { type: 'string' },
        howManyOffers: { type: 'option', options: {
                one: {
                },
                two:{
                    offerTwoTitle: { type: 'string' },
                    offerTwoDescription: { type: 'text' },
                    offerTwoLinkText: { type: 'string' },
                    offerTwoLinkUrl: { type: 'string' },
                }
            }
        },
        rightImage: { type: 'image', height:810, width:515 },
        addOfferToImage: { type: 'option', options: {
                yes: {
                    imageOfferTitle: {type: 'string'},
                    imageOfferDescription: {type: 'text'},
                    imageOfferLinkText: {type: 'string'},
                    imageOfferLinkUrl: {type: 'string'},
                },
                no:{}
            }
        },

    },
    'image-left-two-offers':{
        leftImage: { type: 'image', height:810, width:515 },
        addOfferToImage: { type: 'option', options: {
                yes: {
                    imageOfferTitle: {type: 'string'},
                    imageOfferDescription: {type: 'text'},
                    imageOfferLinkText: {type: 'string'},
                    imageOfferLinkUrl: {type: 'string'},
                },
                no:{}
            }
        },
        heading: { type: 'string' },
        intro: { type: 'text' },
        textOne: { type: 'text' },
        image: { type: 'image', height:400, width:400 },
        textTwo: { type: 'text' },
        offerTitle: { type: 'string' },
        offerDescription: { type: 'text' },
        offerLinkText: { type: 'string' },
        offerLinkUrl: { type: 'string' },

    },
    'image-center':{
        heading: { type: 'string' },
        intro: { type: 'text' },
        text: { type: 'text' },
        image: { type: 'image', height:450, width:650 },
        howManyOffers: { type: 'option', options: {
                one: {
                    offerOneTitle: { type: 'string' },
                    offerOneDescription: { type: 'text' },
                    offerOneLinkText: { type: 'string' },
                    offerOneLinkUrl: { type: 'string' },
                },
                two: {
                    offerOneTitle: { type: 'string' },
                    offerOneDescription: { type: 'text' },
                    offerOneLinkText: { type: 'string' },
                    offerOneLinkUrl: { type: 'string' },
                    offerTwoTitle: { type: 'string' },
                    offerTwoDescription: { type: 'text' },
                    offerTwoLinkText: { type: 'string' },
                    offerTwoLinkUrl: { type: 'string' },
                }
            }},
    },
    'image-quote':{
        heading: { type: 'string' },
        intro: { type: 'text' },
        text: { type: 'text' },
        quote: { type: 'text' },
        image: { type: 'image', height:700, width:650 },
        howManyOffers: { type: 'option', options: {
                one: {
                    offerOneTitle: { type: 'string' },
                    offerOneDescription: { type: 'text' },
                    offerOneLinkText: { type: 'string' },
                    offerOneLinkUrl: { type: 'string' },
                },
                two: {
                    offerOneTitle: { type: 'string' },
                    offerOneDescription: { type: 'text' },
                    offerOneLinkText: { type: 'string' },
                    offerOneLinkUrl: { type: 'string' },
                    offerTwoTitle: { type: 'string' },
                    offerTwoDescription: { type: 'text' },
                    offerTwoLinkText: { type: 'string' },
                    offerTwoLinkUrl: { type: 'string' },
                }
            }},
    },
    'image-bottom':{
        heading: { type: 'string' },
        intro: { type: 'text' },
        textOne: { type: 'text' },
        textTwo: { type: 'text' },
        image: { type: 'image', height:415, width:1920 },
        howManyOffers: { type: 'option', options: {
                one: {
                    offerOneTitle: { type: 'string' },
                    offerOneDescription: { type: 'text' },
                    offerOneLinkText: { type: 'string' },
                    offerOneLinkUrl: { type: 'string' },
                },
                two: {
                    offerOneTitle: { type: 'string' },
                    offerOneDescription: { type: 'text' },
                    offerOneLinkText: { type: 'string' },
                    offerOneLinkUrl: { type: 'string' },
                    offerTwoTitle: { type: 'string' },
                    offerTwoDescription: { type: 'text' },
                    offerTwoLinkText: { type: 'string' },
                    offerTwoLinkUrl: { type: 'string' },
                }
            }
        },
    },
    'image-right': {
        heading: {type: 'string'},
        text: {type: 'text'},
        image: {type: 'image', height:780, width:800 },
    }
};
module.exports = fieldTypes;
