<template>

    <section class="inner h-100 py-lg-0 pl-lg-0">

        <div class="row h-100 ml-lg-0">
            <div class="col-12 col-lg-4 h-100 pl-lg-0">
                <div class="background w-100 h-100 background-center background-cover d-flex pb-3" :style="'background-image: url('+slide.content.leftImage+')'">

                    <div v-if="slide.content.addOfferToImage == 'yes'" class="offer-box white align-self-end mb-5 w-100 mr-5">
                        <div class=" align-top-center">
                            <h3>{{ slide.content.imageOfferTitle }}</h3>
                            <div v-html="slide.content.imageOfferDescription"></div>
                            <p v-if="slide.content.imageOfferLinkUrl">
                                <a :href="slide.content.imageOfferLinkUrl" target="_blank">
                                    {{slide.content.imageOfferLinkText}}
                                </a>
                            </p>

                        </div>
                    </div>


                </div>
            </div>

            <div class="col-12 col-lg-8 pt-lg-5 d-flex flex-column">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-1">
                            {{ chapter.content.chapter }}:
                            <i class="font-italic" v-html="chapter.content.heading"></i>
                        </h2>
                        <h1>
                            {{slide.content.heading}}
                        </h1>
                    </div>
                </div>

                <div class="row flex-grow-1">
                    <div class="col-12 col-lg-4 pr-lg-0">
                        <div class="intro" v-html="slide.content.intro"></div>
                        <div v-html="slide.content.textOne"></div>
                    </div>
                    <div class="col-12 col-lg-4 px-lg-0">
                        <img :src="slide.content.image" />
                    </div>
                    <div class="col-12 col-lg-4 d-flex flex-column">
                        <div class="mb-3" v-html="slide.content.textTwo"></div>
                        <div class="offer-box flex-grow-1 d-flex">
                            <div class=" align-top-center">
                                <h3>{{ slide.content.offerTitle }}</h3>
                                <div v-html="slide.content.offerDescription"></div>
                                <p v-if="slide.content.offerLinkUrl">
                                    <a :href="slide.content.offerLinkUrl" target="_blank">
                                        {{slide.content.offerLinkText}}
                                    </a>
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </section>
</template>
<script>
    export default {

        data(){
            return {

            }
        },
        methods: {

        },
        props: {
            chapter: Object,
            slide: Object,
        },


    };
</script>
