<template>

    <section class="inner h-100 px-lg-0 pb-0 d-flex flex-column">

        <div class="row m-lg-0 px-lg-5">

            <div class="col-12 col-md-12 ">
                    <h2 class="mb-2">
                        {{ chapter.content.chapter }}:
                        <i class="font-italic" v-html="chapter.content.heading"></i>
                    </h2>

                    <h1>
                        {{slide.content.heading}}
                    </h1>
            </div>

            <div class="col-12 col-lg-6">
                <div class="intro" v-html="slide.content.intro"></div>
            </div>

            <div class="col-12 col-lg-3" v-html="slide.content.textOne"></div>
            <div class="col-12 col-lg-3" v-html="slide.content.textTwo"></div>

        </div>
        <div class="row m-lg-0 flex-lg-grow-1 pt-2 pt-lg-5">
            <div class="col-12 p-lg-0 h-100">
                <div class="background w-100 h-100 no-mobile-background background-center background-cover d-lg-flex
" :style="'background-image: url('+slide.content.image+')'">
                    <div class="align-self-center w-100">
                        <div class="row m-lg-0 " >
                            <!-- mobile image -->
                            <div class="col-12 mb-4 d-lg-none">
                                <img :src="slide.content.image" />
                            </div>

                            <div class="col-6 col-lg-3 pl-lg-0 " v-if="slide.content.howManyOffers == 'two'">
                                <div class="offer-box h-100">
                                    <h3>{{ slide.content.offerTwoTitle }}</h3>
                                    <div v-html="slide.content.offerTwoDescription"></div>
                                    <p v-if="slide.content.offerTwoLinkUrl">
                                        <a :href="slide.content.offerTwoLinkUrl" target="_blank">
                                            {{slide.content.offerTwoLinkText}}
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div class="d-none d-lg-block col-lg-6" v-if="slide.content.howManyOffers == 'two'"></div>
                            <div class="d-none d-lg-block col-lg-9" v-if="slide.content.howManyOffers == 'one'"></div>
                            <div class="col-6 col-lg-3 pr-lg-0" v-if="slide.content.howManyOffers == 'one' || slide.content.howManyOffers == 'two'">
                                <div class="offer-box h-100">
                                    <h3>{{ slide.content.offerOneTitle }}</h3>
                                    <div v-html="slide.content.offerOneDescription"></div>
                                    <p v-if="slide.content.offerOneLinkUrl">
                                        <a :href="slide.content.offerOneLinkUrl" target="_blank">
                                            {{slide.content.offerOneLinkText}}
                                        </a>
                                    </p>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>

        </div>


    </section>
</template>
<script>


    export default {

        data(){
            return {
            }
        },
        methods: {

        },
        props: {
            slide: Object,
            chapter: Object,
        },


    };
</script>
